const websiteConfig = {
  name: process.env.NEXT_PUBLIC_WEBSITE_NAME,
  url: process.env.NEXT_PUBLIC_WEBSITE_URL,
  currency: process.env.NEXT_PUBLIC_WEBSITE_CURRENCY,
  phoneCode: process.env.NEXT_PUBLIC_WEBSITE_PHONE_CODE,
  country: process.env.NEXT_PUBLIC_WEBSITE_COUNTRY,
  phone: process.env.NEXT_PUBLIC_WEBSITE_PHONE,
  email: process.env.NEXT_PUBLIC_WEBSITE_EMAIL,
  wheelAvailable: process.env.NEXT_PUBLIC_WEBSITE_WHEEL_AVAILABLE,
  appLink: process.env.NEXT_PUBLIC_APP_LINK,
  betFoundersEnabled: process.env.NEXT_PUBLIC_BETFOUNDERS_ENABLED,
  socialEnabled: process.env.NEXT_PUBLIC_SOCIAL_ENABLED,
  messenger: process.env.NEXT_PUBLIC_MESSENGER,
  facebook: process.env.NEXT_PUBLIC_FACEBOOK,
  instagram: process.env.NEXT_PUBLIC_INSTAGRAM,
  linkedin: process.env.NEXT_PUBLIC_LINKEDIN,
  telegram: process.env.NEXT_PUBLIC_TELEGRAM,
  pinterest: process.env.NEXT_PUBLIC_PINTEREST,
  twitter: process.env.NEXT_PUBLIC_TWITTER,
  youtube: process.env.NEXT_PUBLIC_YOUTUBE,
  facebookTrackingId: process.env.NEXT_PUBLIC_FACEBOOK_TRACKING_ID,
  googleAnalyticsId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
  googleTagId: process.env.NEXT_PUBLIC_GOOGLE_TAG_ID,
  googleTagManagerDataLayer:
    process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_DATA_LAYER,
  hotjarId: process.env.NEXT_PUBLIC_HOTJAR_ID,
  hotjarSV: process.env.NEXT_PUBLIC_HOTJAR_SV,
  tawkId: process.env.NEXT_PUBLIC_TAWK_ID,
  tawkType: process.env.NEXT_PUBLIC_TAWK_TYPE || 'default',
  oneSignal: process.env.NEXT_PUBLIC_ONE_SIGNAL,
};

Object.freeze(websiteConfig);

export default websiteConfig;
