import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';
import dynamic from 'next/dynamic';

import {
  Attached,
  BottomNavigation,
  CircleButton,
  Bookmark,
  SocialSchemaOrg,
} from 'bf-ui';

import styles from './main.layout.scss';

import HomeIcon from '/src/assets/images/icons/home.svg';
import LottoIcon from '/src/assets/images/icons/lotto.svg';
import StarIcon from '/src/assets/images/icons/star.svg';
import WheelIcon from '/src/assets/images/icons/wheel.png';

import websiteConfig from '../../../configs/websiteConfig';
import { setDrawerOpen } from '../../../redux/global.slice';

const MainHeader = dynamic(
  () => import('../../sections/headers/main/main.header'),
  {
    loading: () => <></>,
  }
);

const MainFooter = dynamic(
  () => import('../../sections/footers/main/main.footer'),
  {
    loading: () => <></>,
  }
);

const MainDrawer = dynamic(
  () => import('../../sections/drawers/main/main.drawer'),
  {
    loading: () => <></>,
  }
);

const excludedPaths = [
  '/games/[gameKey]',
  '/login',
  '/sign-up',
  '/landing',
  '/free-spins',
];

const privatePaths = ['/deposit', '/withdraw', '/account'];

function MainLayout({ children }) {
  const router = useRouter();
  const intl = useIntl();
  const dispatch = useDispatch();

  const drawerOpen = useSelector((state) => state.global.drawerOpen);

  const { asPath } = router;

  const sameAs = useMemo(() => {
    const arr = [];

    if (websiteConfig.facebook) {
      arr.push(websiteConfig.facebook);
    }

    if (websiteConfig.instagram) {
      arr.push(websiteConfig.instagram);
    }

    if (websiteConfig.linkedin) {
      arr.push(websiteConfig.linkedin);
    }

    if (websiteConfig.telegram) {
      arr.push(websiteConfig.telegram);
    }

    if (websiteConfig.pinterest) {
      arr.push(websiteConfig.pinterest);
    }

    if (websiteConfig.twitter) {
      arr.push(websiteConfig.twitter);
    }

    if (websiteConfig.youtube) {
      arr.push(websiteConfig.youtube);
    }

    return arr;
  }, []);

  const isExcluded = excludedPaths.includes(router.route);

  const { id } = useSelector((state) => state.user);

  const onChatClick = useCallback(() => {
    if (window?.Tawk_API) {
      window.Tawk_API.toggle();
    }
  }, []);

  useEffect(() => {
    if (!id && privatePaths.includes(asPath)) {
      router.push(`/login?from=/${asPath}`, '/login');
    }
  }, [id, asPath]);

  useEffect(() => {
    if (drawerOpen) {
      dispatch(setDrawerOpen(false));
    }
  }, [router.pathname]);

  return (
    <>
      <main role="document" tabIndex={0}>
        {!isExcluded && <MainHeader />}
        {children}
        {!isExcluded && (
          <>
            <MainFooter />
            <div className="show_mobile">
              <BottomNavigation>
                <Link href="/recent-winners" as="/recent-winners">
                  <a className="tab_link">
                    <span className="icon-cup" />
                    <span>{intl.messages.winners}</span>
                  </a>
                </Link>
                <Link
                  href={!id ? '/login' : '/my-games'}
                  as={!id ? '/login' : '/my-games'}
                >
                  <a className="tab_link">
                    <Image
                      src={StarIcon}
                      width={20}
                      height={20}
                      alt="favorites"
                    />
                    <span>{intl.messages.my_games}</span>
                  </a>
                </Link>
                <Link href="/">
                  <a>
                    <CircleButton>
                      <Image src={HomeIcon} width={30} height={30} alt="home" />
                    </CircleButton>
                  </a>
                </Link>
                <Link href="/lotto" as="/lotto">
                  <a className="tab_link">
                    <Image src={LottoIcon} width={20} height={20} alt="lotto" />
                    <span>{intl.messages.lotto}</span>
                  </a>
                </Link>
                <Link href="/promotions" as="/promotions">
                  <a className="tab_link">
                    <span className="icon-announcement" />
                    <span>{intl.messages.promotions}</span>
                  </a>
                </Link>
              </BottomNavigation>
            </div>
          </>
        )}
        {id && !isExcluded && websiteConfig.wheelAvailable && (
          <Link
            href={id ? '/lucky-wheel`' : '/login?from=/lucky-wheel'}
            as={id ? '/lucky-wheel' : '/login'}
          >
            <a>
              <Bookmark>
                <div className="bookmark_wheel_content">
                  <Image src={WheelIcon} className="bookmark_wheel" />
                  <span className="bookmark_wheel_text">
                    {intl.messages.wheel}
                  </span>
                </div>
              </Bookmark>
            </a>
          </Link>
        )}
        {websiteConfig.tawkId && !isExcluded && (
          <Attached
            className="attached_chat"
            anchor="left"
            label={intl.messages.chat}
            onClick={onChatClick}
          />
        )}
        {drawerOpen && <MainDrawer />}
      </main>
      <SocialSchemaOrg
        name={websiteConfig.name}
        url={websiteConfig.url}
        sameAs={sameAs}
      />
      <style jsx>{styles}</style>
    </>
  );
}

export default MainLayout;
